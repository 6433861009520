<template>
  <div class="login-panel">
    <el-card shadow="hover">
      <h1 class="title">登录账号</h1>
      <el-form ref="form" :model="form" label-width="80px" style="width: 100%">
        <el-form-item label="登录账户：" prop="username">
          <el-input v-model="form.username" name="username" autocomplete="on">
          </el-input>
        </el-form-item>
        <el-form-item label="登录密码：">
          <el-input v-model="form.password" @keyup.enter="login" show-password autocomplete="password">
          </el-input>
        </el-form-item>
        <el-row class="mt-6">
          <div class="ml-auto">
            <el-link type="primary" href="#">忘记密码</el-link>
          </div>
        </el-row>
        <el-row class="mt-6">
          <el-button type="primary" @click="login" class="login-btn" :loading="loading" style="width:100%;">登录</el-button>
        </el-row>
        <el-row class="mt-6">
          <div class="mx-auto">
            还没有账号？<el-link type="primary" href="/#/register">注册账号</el-link>
          </div>
        </el-row>
      </el-form>
    </el-card>
  </div>
</template>

<script>
import * as Api from "@/api";
import dayjs from "dayjs";
import { ElMessageBox } from "element-plus";

export default {
  data() {
    return {
      loading: false,
      form: {
        username: "",
        password: "",
      },
    };
  },
  methods: {
    login() {
      this.loading = true;
      Api.login(this.form)
        .then(() => {
          return Api.getUserInfoApi();
        })
        .then((res) => {
          return this.handleExpire(res);
        })
        .then((res) => {
          let diff = dayjs(res.data.data.expDate).diff(dayjs(), "day");
          if(diff > 0 && res.data.data.state === 0 && (res.data.data.tel === undefined || res.data.data.tel === null || res.data.data.tel.trim() === "") ) {
            this.openPhoneNumberPrompt()
            .then(() => {
              this.$router.replace("/");
              Api.sucessMessage("登录成功");
            })
          } else {
            this.$router.replace("/");
            Api.sucessMessage("登录成功");
          }

        })
        .finally(() => {
          this.loading = false;
        });
    },
    handleExpire(res) {
      return new Promise((resolve) => {
        let diff = dayjs(res.data.data.expDate).diff(dayjs(), "day");
        if (diff < 3) {
          let message = "您的授权即将到期，请及时续费！";
          if(diff < 0) {
            message = "当前授权已到期"
          }
          ElMessageBox.alert(message, "警告", {
            confirmButtonText: "确认",
            type: "warning",
            callback: (action) => {
              if (action === "confirm") resolve(res);
            },
          });
        } else {
          resolve(res);
        }
      })
    },
    openPhoneNumberPrompt() {
      return ElMessageBox.prompt("请输入手机号，完善个人信息(仅用于账户到期提醒服务)","提示", {
        confirmButtonText: "确认",
        showClose: false,
        showCancelButton: false,
        closeOnClickModal: false,
        beforeClose: (action, instance, done) => {
          instance.confirmButtonLoading = true
          Api.setPhoneNumber(instance.inputValue)
          .then(() => {
            done();
          })
          .finally(() => {
            instance.confirmButtonLoading = false;
          })
        }
      })
    }
  },
};
</script>

<style scoped>
.login-panel {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.el-card {
  width: 400px;
  border-radius: 20px;
  padding-top: 20px;
  padding-left: 20px;
  padding-right: 20px;
}
.login-btn {
  width: 300px;
  margin-left: auto;
  margin-right: auto;
}
</style>

